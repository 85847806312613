<template>
      <section class="mission">
        <h1>Missie</h1>
        <p>Wij streven naar een betere startpositie voor kinderen met een leerachterstand</p>
        <div class="row">
            <div class="mission-col">
                <router-link @click="scrollToTop" to="/over-ons">
                    <h3>Leerachterstand wegwerken</h3>
                     <p>Onze missie is om jonge kinderen met een leerachterstand uit achterstandswijken te helpen met hun schoolwerkzaamheden, zodat zij vanuit een betere startpositie opstromen naar het voorgezet onderwijs. Vervolgens willen we de kinderen gestructureerd begeleiden tijdens de middelbare school, waardoor ze zich optimaal kunnen ontwikkelen. </p>        
                </router-link> 
            </div>
            <div class="mission-col">
                <router-link @click="scrollToTop" to="/het-team">
                    <h3>Een team van hoogopgeleiden</h3>
                    <p>Wij vinden het heel belangrijk dat de kinderen een kwalitatieve begeleiding krijgen. Om dit te bereiken zijn we constant bezig met het optimaliseren van onze methoden en het investeren in ons team. Zo bestaat ons team uit hoogopgeleide vrijwilligers die een grote affiniteit hebben met onderwijs en kinderen.</p>
                </router-link> 
            </div>
            <div class="mission-col">
                <router-link @click="scrollToTop" to="/over-ons">
                    <h3>Meer dan alleen bijles</h3>
                    <p>De leerlingen die een extra steuntje in de rug nodig hebben krijgen een persoonlijke begeleider. Iedere week hebben deze leerlingen een contactmoment met de begeleiders. In dit gesprek wordt de planning en de doelstelling van de week besproken. De begeleiders houden de voortgang van de leerlingen gedurende het jaar in de gaten. Dit wordt uitvoerig besproken met de ouders en scholen.</p>
                </router-link> 
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "MissionSection",
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}
</script>

<style scoped>
.mission {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 100px;        /* t lr b */
}

.mission-col {
    flex-basis: 31%;
    background: #FFF3F3;
    border-radius: 10px;
    margin-top: 5px;
    box-sizing: border-box;
    transition: 0.5s;
    cursor: pointer;
}
.mission-col:hover {
    box-shadow: 0 0 20px 0px rgba(0,0,0,0.2);
    transform: translateY(-3px);
}
.mission-col a h3{
    color: #000000;
}
.mission .row a {
    display: block;
    padding: 20px 12px;
    text-decoration: none;
}

@media(max-width: 978px) {
    .mission {
        padding: 40px 0 5px;        /* t lr b */
    }
    .mission-col {
        margin: -10px 0px 50px;
    }
    .row {
        flex-direction: column;
    }
    h1 {
        font-size: 20px;
    }
    h3 {
        font-size: 16px;
    }

}
</style>