<template>
      <footer class="footer-area">
        <div class="custom-shape-divider-top-1680641552">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
            </svg>
        </div>
        
        <div class="main">
            <div class="footer">
                <div class="single-footer footer-text">
                    <h4>
                       Stichting 550
                    </h4>
                    <p>
                        Naast het academische aspect, wordt er bij Stichting550 ook veel aandacht besteed aan het mentale welzijn van de leerlingen. Huiswerk kan soms voor stress zorgen, en het is belangrijk dat leerlingen leren om hier op een gezonde manier mee om te gaan. De begeleiders bieden een luisterend oor, geven advies en helpen de leerlingen om stress te verminderen en hun zelfvertrouwen te vergroten.                         
                    </p>
                    <div class="footer-social">
                        <a href="https://www.facebook.com/stichting550" target="_blank"><i class="fa-brands fa-facebook"></i></a> 
                        <a href="https://www.instagram.com/stichting550" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/stichting550/" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
                <div class="single-footer">
                    <h4>Menu</h4>
                    <ul>
                        <router-link @click="scrollToTop" to="/"><li><i class="fa-solid fa-house"></i>Home</li></router-link> 
                        <router-link @click="scrollToTop" to="/steun-ons"><li><i class="fa-solid fa-hand-holding-heart"></i>Steun ons</li></router-link>
                        <router-link @click="scrollToTop" to="/over-ons"><li><i class="fa-solid fa-address-card"></i>Over ons</li></router-link> 
                        <router-link @click="scrollToTop" to="/het-team"><li><i class="fa-solid fa-user-group"></i>Het team</li></router-link> 
                        <router-link @click="scrollToTop" to="/contact"><li><i class="fa-solid fa-message"></i>Contact</li></router-link> 
                    </ul>
                </div>
                <div class="single-footer">
                    <h4>Aanmelden</h4>
                    <ul>
                        <router-link @click="scrollToTop" to="/word-vrijwilliger"><li><i class="fa-solid fa-handshake-angle"></i>Word vrijwilliger</li></router-link>                 
                        <router-link @click="scrollToTop" to="/meld-uw-kind-aan"><li><i class="fa-solid fa-child"></i>Meld uw kind aan</li></router-link>
                    </ul>
                </div>
                <div class="single-footer">
                    <h4>Contact</h4>
                    <ul class="contact-list">
                        <li><i class="fa-sharp fa-solid fa-location-dot"></i>Hillevliet 90<br> 3074 KD Rotterdam</li>
                        <li><i class="fa-solid fa-phone"></i>0617451663</li>
                        <li><i class="fa-solid fa-envelope"></i>info@stichting550.nl</li>
                    </ul>
                </div>
            </div>
            <div class="copy">
                <p>&copy; {{ new Date().getFullYear() }} Stichting 550.</p>
            </div>
        </div>  
    </footer>
</template>

<script>
export default {
    name: "Footer",
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}
</script>

<style>
.custom-shape-divider-top-1680641552 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1680641552 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
}

.custom-shape-divider-top-1680641552 .shape-fill {
    fill: #FFFFFF;
}




.footer-area{
    width: 100%;
    color: #FFF;
    position: relative;
    background-image: linear-gradient(rgba(4,9,30,0.3), rgba(4,9,30,0.3)), url(../assets/images/footer-achtergrond.png);
    background-position: center;
    background-size: cover;
}
.footer-area p {
    color: #FFF;
}
.main {
    width: 80%;
    margin: 0 auto;
    /* background-color: red; */
    padding-top:110px;
}
.footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}
.footer .footer-text p{
    padding: 0;
    padding-left: 1px;
    padding-bottom: 5px;
}
.single-footer:first-child {
    width: 400px;
}
.single-footer:nth-child(2){
    position: relative;
    left: 50px;
}
.single-footer {
    width: 230px;
    padding-bottom: 30px;
}
.single-footer h4 {
    font-size: 22px;
    margin-bottom: 20px;
}
.single-footer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 0;
    margin: 0;
}
.single-footer ul li {
    position: relative;
    padding-left: 32px;
}



.single-footer ul a { 
    text-decoration: none;
    color: #FFF;
    width: fit-content;
    display: block;
}


.single-footer .contact-list a:hover { 
    color: #FFFFFF;
    transition: 0.5s;
}

.single-footer ul a li i {
    position: absolute;
    left: 0;
    top: 4px;
 }

 .single-footer .contact-list li i {
    position: absolute;
    left: 0;
    top: 4px;
 }


.footer-social a {
    color: #FFF;
    border: 1px solid #FFF;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

.footer-social a:not(:first-child)  {
    margin-left: 14.5px;
}

.footer-social a:hover {
    border: 1px solid #F44336;
    background: #F44336;
    transition: 1s;
}
.footer-social {
    margin-top: 20px;
}
.footer-social i {
    position:relative;
    top: calc(50% - 20px); /* 50% - 3/4 of icon height */
}
.copy {
    text-align: center;
    padding-bottom: 20px;
}

@media(max-width: 1075px) {
    .single-footer:nth-child(2){
        left: 0px;
    }
}

@media(max-width: 978px) {
    .footer {
        flex-direction: column;
    }
    .single-footer:first-child {
        width: 230px;
    }
    .single-footer:nth-child(2){
        left: 0px;
    }
    .single-footer {
        padding-bottom: 40px;
    }
    .copy {
        padding-top: 10px;
    }
    .footer .footer-text p{
        margin-top:-4.5px;
        margin-bottom:23px;
    }
}

</style>