<template>
  <section class="navbar">
    <nav>
      <router-link to="/" class="logo-container">
          <img src="../assets/images/st550-nieuwe-logo-transparant.png" alt="" class="logo-img"/>
          <img src="../assets/images/st550-text-logo.png" alt="" class="logo-text"/>
        </router-link> 
      <div class="nav-links" id="navLinks" :style="[menuOpen ? {'right' : 0} : {'right' : '-100vw'}]">
        <i class="fa fa-times" @click="hideMenu()"></i>
        <ul>
          <router-link @click="scrollToTop(); hideMenu();" to="/"><li>HOME</li></router-link> 
          <router-link @click="scrollToTop(); hideMenu();" to="/over-ons"><li>OVER ONS</li></router-link> 
          <router-link @click="scrollToTop(); hideMenu();" to="/het-team"><li>HET TEAM</li></router-link> 
          <router-link @click="scrollToTop(); hideMenu();" to="/word-vrijwilliger"><li>WORD VRIJWILLIGER</li></router-link> 
          <router-link @click="scrollToTop(); hideMenu();" to="/contact"><li>CONTACT</li></router-link> 
        </ul>
      </div>
      <i class="fa fa-bars" @click="showMenu()"></i>
    </nav>
  </section>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    showMenu(){
      this.menuOpen = true;
      document.documentElement.style.overflow = 'hidden';
    },
    hideMenu(){
      this.menuOpen = false;
      document.documentElement.style.overflow = 'auto'
      },
    scrollToTop() {
          window.scrollTo(0,0);
      }
  }
};
</script>

<style>
.navbar {
  /* background-color: red; */
  width: 100%;
  margin: auto;
}
nav {
  display: flex;
  width: 80%;
  margin: auto;
  padding: 40px 0px 34px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.logo-container {
  position: relative;
  left: 0px;
}
.logo-img {
  width: 65px;
  height: 55px;
  position: absolute;
  bottom: -20px;
  left: 25.5px;
}
.logo-text {
  position: absolute;
  width: 89px;
  bottom: -28px;
}
.nav-links {
  flex: 1;
  text-align: right;
}
.nav-links ul a {
  list-style: none;
  display: inline-block;
  /* padding: 8px 12px; */
  position: relative;
  cursor: pointer;
  /* background-color: pink; */
  /* margin: 8px 12px; */
  margin-left:28.3px;
  text-decoration: none;
  color: #000;
  font-size: 16px;
}
.nav-links ul a:last-child {
  margin-right:12px;
}
.nav-links ul a li::after {
  content: "";
  width: 0%;
  height: 2px;
  background: #f44336;
  display: block;
  margin: auto;
  transition: 0.5s;
}
.nav-links ul a li:hover::after {
  width: 100%;
}
nav .fa {
    display: none;
}

/* For landscape only */
@media only screen and (max-width: 978px) and (orientation: landscape) {
    .nav-links ul {
        margin: auto;
        margin-top: 80px !important;
    }
    nav .fa-times {
        right: 73px !important;
    }
    .nav-links {
        overflow-y: scroll;
    }
    .nav-links ul a:nth-child(5){
        margin-bottom: 20px;
    }
}

@media(max-width: 978px) {
    nav {
        padding-bottom: 32px;
    }
    .nav-links {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background: #FFFFFF;
        height: 100vh;
        width: 100vw;
        top: 0px;
        right: -100vw;
        text-align: left;
        z-index: 2;
        transition: 1s;
    }
    .nav-links ul a li::after{
        display: none;
    }
    nav .fa {
        display: block;
        color: #000;
        margin: 10px;
        font-size: 22px;
        cursor: pointer;
    }
    nav .fa-times {
        position: absolute;
        top: 40px;
        right: 39px;
    }
    .nav-links ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -50px;
    }
    .nav-links ul a li{
        font-size: 20px;
        padding: 20px;
    }
    .logo-img {
      width: 55px;
      height: 45px;
      bottom: -14px;
    }
    .logo-text {
      width: 79px;
      bottom: -22px;
    }
}
</style>
