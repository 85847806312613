<template>
  <div>
    <MissionSection class="hidden"/>
    <MethodSection class="hidden"/>
    <BranchesSection class="hidden"/>
    <TestimonialsSection class="hidden"/>
    <RegisterChildSection class="hidden"/>
  </div>
</template>

<script>
import MissionSection from '../components/MissionSection.vue';
import MethodSection from '../components/MethodSection.vue';
import BranchesSection from '../components/BranchesSection.vue';
import TestimonialsSection from '../components/TestimonialsSection.vue';
import RegisterChildSection from '../components/RegisterChildSection.vue';


// @ is an alias to /src

export default {
    name: "Home",
    components: { 
      MissionSection,
      MethodSection,
      BranchesSection,
      TestimonialsSection,
      RegisterChildSection
     },
     mounted(){
      this.$store.commit('updateHeaderTitle', 'Stichting 550')
      this.$store.commit('updateHeaderSubtitle', 'Een stichting die zich inzet om kinderen volledig kosteloos te helpen hun leerachterstand in te perken.')
      this.$store.commit('updateHeaderBtn', true)


      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
          /* to show animation multiple times */
          // else {
          //   entry.target.classList.remove('show')
          // }
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Home - Stichting 550"
      document.querySelector('meta[name="description"]').setAttribute("content", "Een stichting die zich inzet om kinderen volledig kosteloos te helpen hun leerachterstand in te perken.");

      
    }
};
</script>

<style scoped>
.hidden {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1.5s;
}

.show {
  opacity: 1;
  transform: translateY(0);
}

</style>
