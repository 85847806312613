<template>
  <div id="nav">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
    <NavBar />
    <Header class="hidden"/>

  </div>
  <router-view />
  <Footer/>

</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";



export default {
  name: "Home",
  components: {
    NavBar,
    Header,
    Footer
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
          /* to show animation multiple times */
          // else {
          //   entry.target.classList.remove('show')
          // }
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))
  }
};
</script>

<style>
#app {
  font-family: "Poppins", sans-serif;
}
* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
h1 {
    font-size: 36px;
    font-weight: 600;
}
h3 {
    text-align: center;
    font-weight: 600;
    margin: 10px 0;
}
p {
    color: #606060;
    font-size: 14px;
    line-height: 22px;
    padding: 5px;
    margin: 0;
}
.row {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.hidden {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1.5s;
}

.show {
  opacity: 1;
  transform: translateY(0);
}

@media(max-width: 978px) {
    html, body {
        overflow-x: hidden;
    }
    .row {
        flex-direction: column;
    }
    h1 {
        font-size: 20px;
    }
    h3 {
        font-size: 16px;
    }
}
</style>
