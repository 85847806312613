<template>
    <div class="testimonials-container">
        <section class="testimonials">
            <h1>Wat onze leerlingen zeggen</h1>
            <p>Een samenvatting van een interview met twee van onze leerlingen</p>
            <div class="row">
                <div class="testimonial-col">
                    <img src="../assets/images/mo-foto-hoofd-klein.jpg">
                    <div>
                        <p>Toen ik in groep 7 zat hebben mijn ouders mij ingeschreven bij Stichting550. Door wekelijks lessen hier te volgen werd ik goed voorbereid voor de cito in groep 8. De docenten zijn erg aardig en behulpzaam, je kan altijd naar ze toe als je iets niet begrijpt. Uiteindelijk heb ik een cito score gehaald van 549. Nu zit ik op de middelbare school en doe ik vwo. Nog steeds kom ik wekelijks naar Stichting550 omdat ik merk dat het mij erg helpt. Dit zie ik ook terug in mijn schoolresultaten.</p>
                        <h3>Mohamed</h3>
                    </div>
                </div>
                <div class="testimonial-col">
                    <img src="../assets/images/ihsane-foto-hoofd-klein.jpg">
                    <div>
                        <p>Elke week kom ik met veel plezier naar Stichting550. Ik kan hier goed leren en serieus zijn. Begrijpend lezen heb ik altijd moeilijk gevonden. Ik vind het lastig om mijn concentratie er bij te houden wanneer ik een tekst lees. Bij Stichting550 leer ik hoe ik hiermee moet omgaan. Op school gaat het nu gelukkig beter. Ik vind het leuk dat ik hier nieuwe mensen kan ontmoeten. De dochter van de nicht van mijn moeder heb ik hier leren kennen. Ik hoop nog lang bij Stichting550 te kunnen blijven en veel te mogen leren.</p>
                        <h3>Ihsane</h3>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
name: "TestimonialsSection"
}
</script>

<style>
.testimonials-container  {
    background-color: #f2f2f2;
}
.testimonials {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 100px;
}
.testimonial-col {
    flex-basis: 44%;
    border-radius: 10px;
    margin-top: 5px;
    text-align: left;
    background: #FFF3F3;
    padding: 25px 25px 10px;
    display: flex;
}
.testimonial-col img {
    height: 80px;
    margin-left: 5px;
    margin-right: 30px;
    border-radius: 50%;
}
.testimonial-col p {
    padding: 0;
}
.testimonial-col h3 {
    margin-top: 15px;
    text-align: left;
}
.testimonial-col .fa-solid {
    color: #F44336;
}
@media(max-width: 978px) {
    .testimonials {
        padding: 40px 0 5px;    
    }
    .testimonial-col {
        margin: -10px 0px 50px;
    }
    .testimonial-col img {
        margin-left: 0px;
        margin-right: 15px;
        height: 50px;
    }
}
</style>