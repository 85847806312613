import { createStore } from "vuex";

export default createStore({
  state: {
    titleHeader: 'Stichting 550',
    subtitleHeader: 'Een stichting die zich inzet om kinderen volledig kosteloos te helpen hun leerachterstand in te perken.',
    showBtnHeader: true,
    members: [{ 
                id: 'mohamed-alkaduhimi',
                name: 'Mohamed Alkaduhimi',
                branche: 'Vestiging Amsterdam', 
                motivation: '"Het is altijd zo prachtig om te zien dat je elke keer een jaar ingaat om de kinderen iets te leren en het jaar eindigt als degene die zelf zoveel van de kinderen heeft geleerd!"',
                text: 'Mijn naam is Mohamed Alkaduhimi en ben een van de oprichters van Stichting550. Binnen het bestuur ben ik de penningmeester. Ik begon als begeleider bij project550. Dat was het pilotproject dat door Mohamed Al-Saady was gestart voordat we de stichting hadden opgezet. Ik was zelf van plan om even te helpen en weg te gaan, maar ik zag hoe ons simpel projectje zoveel impact had op de kinderen. Ik vroeg mezelf af: laat je het toe dat al dit potentiële talent kwijtraakt doordat de maatschappij deze verwaarloost? Het antwoord was dat ik in het afgelopen 8 jaar mezelf maar 2 zaterdagen heb afgemeld voor het geven van bijles. Als de maatschappij deze kinderen onderschat en hun talent verkwanselt, zal ik in ze blijven geloven en meehelpen hen een duwtje in de rug te geven.',
                image: 'mohamed-alkaduhimi.png'
              },
              { 
                id: 'mohamed-al-saady',
                name: 'Mohamed Al-Saady',
                branche: 'Vestiging Rotterdam', 
                motivation: '"Motivatie Mohamed Al-Saady."',
                text: 'Text Mohamed Al-Saady',
                image: 'mohamed-al-saady.jpg'
              },
              {
                id: 'ahmed-jabori',
                name: 'Ahmed Jabori',
                branche: 'Vestiging Den Haag',  
                motivation: '"Ik wil anderen ook dezelfde kansen geven die ik destijds heb gekregen."',
                text: 'Mijn naam is Ahmed en ik geef al twee jaar les bij Stichting550. Maar mijn avontuur begon 9 jaar terug al toen ik zelf bijles kreeg in groep 7. Met vmbo-t als voorlopig advies hoopte ik op een beter resultaat en ging ik hard werken. Na 2 jaar heb ik, onder begeleiding van de docenten van Stichting550, een havo/vwo advies behaald en heb ik uiteindelijk mijn vwo diploma behaald. Nu studeer ik econometrie aan de erasmus universiteit. De reden dat ik als docent bij Stichting550 ben begonnen is omdat ik anderen ook dezelfde kansen wil geven die ik destijds heb gekregen. Dit doe ik met veel plezier en enthousiasme.',
                image: 'no-picture.png'
              },
              { 
                id: 'zahra-nassralla',
                name: 'Zahra Nassralla',
                branche: 'Vestiging Den Haag', 
                motivation: '"Ik zie hoe gedreven de meeste leerlingen zijn en dit motiveert mij ook."',
                text: 'Mijn naam is Zahra en ik ben 22 jaar oud. Ik geef al 3 jaar les bij Stichting550.<div class="new-line">Lesgeven bij Stichting550 biedt de mogelijkheid om een positieve impact te hebben op leerlingen en bij te dragen aan hun ontwikkeling. Door je kennis en vaardigheden te delen, help je kinderen groeien en bloeien in een veilige en ondersteunende omgeving. Het is geweldig om te zien hoe hun ogen oplichten wanneer ze iets nieuws leren.</div><div class="new-line">Bovendien heeft het lesgeven bij Stichting550 mijn communicatie- en leiderschapsvaardigheden versterkt, terwijl het ook een gevoel van voldoening geeft.</div>',
                image: 'zahra-nassralla.png'
              },
              { 
                id: 'sarah-zanky',
                name: 'Sarah Zanky',
                branche: 'Vestiging Den Haag', 
                motivation: '"Ik hoop de kinderen te kunnen laten inzien wat ze allemaal waard zijn."',
                text: 'Ik ben Sarah en ben de projectleider van Stichting550 Den Haag. Daarnaast geef ik ook voornamelijk rekenlessen aan de leerlingen.<div class="new-line">Ik ben al een aantal jaar actief bij Stichting550, omdat ik echt gek ben op de kinderen die wij mogen helpen. Ik hoop de kinderen te kunnen laten inzien wat ze allemaal waard zijn en hoeveel mooie kwaliteiten ze bezitten. Elk kind heeft super veel te bieden en is meer dan zijn/haar schoolresultaten.</div>',
                image: 'sarah-zanky.png'
              },
              { 
                id: 'ali-al-saady',
                name: 'Ali Al-Saady',
                branche: 'Vestiging Rotterdam', 
                motivation: '"Ik wil de leerlingen laten inzien dat met veel inzet en hard werken, alles mogelijk is."',
                text: 'Mijn naam is Ali en geef sinds het schooljaar 21/22 les bij Stichting550. In het dagelijks leven werk ik als Software Developer bij een IT-bedrijf.<div class="new-line">De reden dat ik me aangesloten heb bij Stichting550 is omdat ik naast m’n werk graag mijn steentje wil bijdragen aan de maatschappij. Mijn broer Mohamed is een van de oprichters van Stichting550. Toen hij mij vroeg om mee te helpen bij de stichting hoefde ik niet lang na te denken. Ik vind kinderen namelijk erg leuk en wil ook graag meehelpen.</div><div class="new-line">Ik hoop dat ik met mijn hulp iets kan betekenen voor de leerlingen. Misschien net dat duwtje in de rug dat het verschil kan maken. Met veel inzet en hard werk weet ik zeker dat de leerlingen heel ver kunnen komen. Dit is dan ook de boodschap die ik ze wil meegeven.</div>',
                image: 'ali-al-saady.jpg'
              },
              { 
                id: 'taha-alkaduhimi',
                name: 'Taha Alkaduhimi',
                branche: 'Vestiging Amsterdam', 
                motivation: '"Mijn gemotiveerde leraren hebben ervoor gezorgd dat ik ook een steentje bijdraag aan de maatschappij."',
                text: 'Ik begon in 2015 als leerling bij Stichting550. Mijn oom gaf les, dus ging ik elke week met hem mee van Amsterdam naar Den Haag. Ik zag hoe gemotiveerd mijn leraren waren, die van het hele land kwamen om ons vrijwillig de kans te geven om een welverdiend basisschool advies te verkrijgen. Daarom ben ik zelf begonnen met bijles te geven om een steentje bij te dragen aan de maatschappij.<div class="new-line">Door Stichting550 heb ik verschillende vaardigheden beheerst, zowel als leerling als docent. Ook door het uitgebreide team heb ik mijzelf beter leren kennen. Hierdoor heb ik een studie gekozen die het best bij mij past, namelijk civiele techniek. Ik kijk ernaar uit naar de nieuwe ervaringen die ik zal opbouwen bij Stichting550.</div>',
                image: 'taha-alkaduhimi.jpeg'
              },
              { 
                id: 'ali-jabori',
                name: 'Ali Jabori',
                branche: 'Vestiging Den Haag', 
                motivation: '"Veel meer kinderen bezitten de (\'verscholen\') capaciteit voor een hoger advies, dat gepaard gaat met een breder toekomst perspectief."',
                text: 'Mijn verhaal begint als eerste generatie leerling van Stichting550. Destijds lag mijn advies ruim onder de gewenste 550 punten, sterker nog: mij was het kader onderwijs geadviseerd, waar ik sterk ontevreden mee was.<div class="new-line">Op het \'perfecte\' moment kwamen we (mijn tweelingbroer en ik) in aanraking met Stichting550, waarna we deelnamen als eerste generatie leerlingen. 550 was klaarduidelijk het streven en zo gezegd, zo gedaan.</div><div class="new-line">Middels de inspanningen van de vrijwilligers destijds (Mohamed Al-Saady en Mohamed Alkaduhimi), is mijn toekomstperspectief sterk vooruitgegaan. Ik heb mogen deelnemen aan het Voortgezet Wetenschappelijk Onderwijs (VWO) en ben vervolgens elektrotechniek gaan studeren aan de Technische Universiteit van Eindhoven.</div><div class="new-line">Zelfs tot in het heden ervaar ik de vruchten van hun moeite destijds, waarvoor ik hen zeer dankbaar ben. Het is dan vanzelfsprekend dat ik het werk voortzet als docent bij 550. Per 2023 heb ik een jaar achter de rug, maar ik oog om nog velen jaren actief aanwezig te zijn bij de organisatie.</div>',
                image: 'no-picture.png'
              },
              { 
                id: 'osama-zanky',
                name: 'Osama Zanky',
                branche: 'Vestiging Rotterdam', 
                motivation: '"Motivatie Osama Zanky"',
                text: 'Text Osama Zanky',
                image: 'no-picture.png'
              },
              { 
                id: 'ali-alawy',
                name: 'Ali Alawy',
                branche: 'Vestiging Rotterdam', 
                motivation: '"Het is hartverwarmend om te zien hoe studenten stappen zetten en vooruitgang boeken, zowel op academisch gebied als in hun persoonlijke ontwikkeling."',
                text: 'Ik ben Ali Alawy, 22 jaar oud en student Lerarenopleiding Nederlands, op Hogeschool Rotterdam.<div class="new-line">Mijn keuze om huiswerkbegeleiding te geven bij Stichting550 kwam voort uit mijn wens om anderen te helpen en mijn kennis te delen. Ik geloof dat onderwijs een krachtig middel is om mensen te inspireren en te laten groeien.</div><div class="new-line>"Mijn keuze om huiswerkbegeleiding te geven bij Stichting550 kwam voort uit mijn wens om anderen te helpen en mijn kennis te delen. Ik geloof dat onderwijs een krachtig middel is om mensen te inspireren en te laten groeien.</div>',
                image: 'no-picture.png'
              },
              { 
                id: 'yasser-zanky',
                name: 'Yasser Zanky',
                branche: 'Vestiging Rotterdam', 
                motivation: '"Motivatie Yasser Zanky"',
                text: 'Text Yasser Zanky',
                image: 'no-picture.png'
              },
              { 
                id: 'dalia-al-rawaf',
                name: 'Dalia Al-Rawaf',
                branche: 'Vestiging Den Haag', 
                motivation: '"Motivatie Dalia Al-Rawaf"',
                text: 'Text Dalia Al-Rawaf',
                image: 'no-picture.png'
              },
              { 
                id: 'nour-alkaduhimi',
                name: 'Nour Alkaduhimi',
                branche: 'Vestiging Amsterdam', 
                motivation: '"Motivatie Nour Alkaduhimi"',
                text: 'Text Nour Alkaduhimi',
                image: 'no-picture.png'
              }
]
  },
  mutations: {
    updateHeaderTitle (state, title) {
      state.titleHeader = title
    },
    updateHeaderSubtitle (state, subtitle) {
      state.subtitleHeader = subtitle
    },
    updateHeaderBtn (state, showbtn){
      state.showBtnHeader = showbtn
    },
  },
  actions: {},
  modules: {},
});
