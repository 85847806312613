<template>
      <section class="register-child">
        <h1>Meld uw kind aan</h1>
        <OrangeBtn :linkTo="'register-child'" :text="'Aanmelden'"/>
    </section>
</template>

<script>
import OrangeBtn from "@/components/OrangeBtn.vue";

export default {
    name: "RegisterChildSection",
    components: {
        OrangeBtn
    }
}
</script>

<style scoped>
.register-child {
    margin: 170px auto 175px;
    width: 80%;
    background-image: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(../assets/images/register-child-banner.jpg);
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    text-align: center;
    padding: 80px 0;
}
.register-child h1 {
    color: #FFF;
    margin-bottom: 40px;
    padding: 0;
}
@media(max-width: 978px) {
    .register-child {
        margin: 70px auto 90px;
    }
}
</style>