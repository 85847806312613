import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index.js"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/over-ons",
    name: "AboutUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
  },
  {
    path: "/het-team",
    name: "TheTeam",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TheTeam.vue"),
  },
  {
    path: "/team-lid/:memberid",
    name: "TeamMember",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TeamMember.vue"),
      beforeEnter(to) {
        const id = to.params.memberid;
        const localMembers = store.state.members;
        const exists = localMembers.some((member) => member.id === id);

        if(!exists) return {
          name: "NotFound",
          params: {pathMatch: to.path.substring(1).split("/")},
          query: to.query,
          hash: to.hash
        };

      }
  },
  {
    path: "/word-vrijwilliger",
    name: "BecomeVolunteer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BecomeVolunteer.vue"),
  },
  {
    path: "/meld-uw-kind-aan",
    name: "RegisterChild",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterChild.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
  },
  {
    path: "/steun-ons",
    name: "SupportUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SupportUs.vue"),
  },
  {
    path: "/steun-ons/periodieke-donatie",
    name: "PeriodicDonation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PeriodicDonation.vue"),
  },
  {
    path: "/steun-ons/bedankt",
    name: "ThankYou",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ThankYou.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
