<template>
    <section class="branches">
        <h1>Vestigingen</h1>
        <p>Wij zijn gevestigd in Rotterdam en Amsterdam</p>
        <div class="row">
            <!-- <div class="branches-col">
                <img src="../assets/images/nelson_mandela_plein_1_foto.jpg">
                <div class="layer"> 
                    <div class="address">
                            Mandelaplein 1 <br>2572 HT <br>Den Haag
                    </div>
                </div>
            </div> -->
            <div class="branches-col">
                <img src="../assets/images/hillevliet_foto.jpg">
                <div class="layer"> 
                    <div class="address">
                            Hillevliet 90<br>3074 KD <br>Rotterdam
                    </div>
                </div>
            </div>
            <div class="branches-col">
                <img src="../assets/images/ontwerp_appartementencomplex-01.jpg">
                <div class="layer"> 
                    <div class="address">
                            Jan tooropstraat 6A<br>1062 BM <br>Amsterdam
                    </div>
                    </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "BranchesSection"
}
</script>

<style>
.branches {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 100px;
}
.branches-col {
    flex-basis: 48%;
    border-radius: 10px;
    margin-top: 5px;
    position: relative;
}
.branches-col img {
    width: 100%;
    display: block;
    border-radius: 10px;
    height: 100%;
    /* min-height: 485px; */
}
.layer {
    background: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
    border-radius: 10px;
}
.layer:hover {
    background: rgba(4,9,30,0.7);
}
.layer .address {
    font-weight: 500;
    color: #FFF;
    font-size: 26px;
    position: absolute;
    top: 0%;
    width: 100%;
    position: absolute;
    opacity: 0;
    transition: 0.5s;
}
.layer:hover .address {
    top:50%;
    opacity: 1;
    margin-top: -2.5em;
}
@media(max-width: 978px) {
    .branches {
        padding: 40px 0 5px;    
    }
    .branches-col {
        margin: -10px 0px 50px;
    }
    .branches-col img {
        min-height: 200px;
    }
    .layer .address {
        font-size: 16px;
    }
}

</style>