<template>
      <div class="method-container">
        <section class="method">
            <h1>Werkwijze</h1>
            <p>Op basis van leeftijd en niveau worden de kinderen ingedeeld in een groep</p>
            <div class="row">
                <div class="method-col">
                    <img src="../assets/images/girls-school-laughing.jpg">
                    <h3>Groep 7 & 8</h3>
                    <p>Deze twee groepen leerlingen zijn een bijzondere en belangrijke fase van hun basisschoolperiode ingegaan. Bij Stichting550 worden de leerlingen van groep 7 en 8 gestimuleerd om hun talenten te ontwikkelen, samen te werken en zich voor te bereiden op hun toekomstige avonturen. Elke klas bestaat uit ongeveer 11 leerlingen en zal eigen lesmateriaal krijgen van de stichting.</p>
                </div>
                <div class="method-col">
                    <img src="../assets/images/muslim-teacher.jpg">
                    <h3>Één op één begeleiding</h3>
                    <p>Bij Stichting550 bieden we extra persoonlijke begeleiding voor leerlingen die een extra steuntje in de rug nodig hebben. Dit gebeurt in samenspraak met de ouders. Met de juiste begeleiding zijn ze klaar om het volgende avontuur van hun schoolloopbaan aan te gaan en vol vertrouwen de toekomst tegemoet te treden. </p>
                </div>
                <div class="method-col">
                    <img src="../assets/images/muslim-kids-school.jpg">
                    <h3>Voortgezet Onderwijs</h3>
                    <p>De huiswerkbegeleiding groep van Stichting550 is bedoeld voor leerlingen van alle leeftijden van het voortgezet onderwijs. Het doel is om hen te helpen bij het plannen en organiseren van hun huiswerk, het vergroten van hun studievaardigheden en het versterken van hun zelfvertrouwen. Op deze manier worden de leerlingen gestimuleerd om het beste uit zichzelf te halen.</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "MethodSection"
}
</script>

<style>
.method-container  {
    background-color: #f2f2f2;
}
.method {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 80px 0 100px;
}
.method-col {
    flex-basis: 31%;
    border-radius: 10px;
    margin-top: 5px;
    text-align: left;
}
.method-col img { 
    width: 100%;
    border-radius: 10px;
}
.method-col p {
    padding: 0; 
}
.method-col h3 {
    margin-top: 16px;
    margin-bottom: 15px;
    text-align: left;
}
@media(max-width: 978px) {
    .method {
        padding: 40px 0 5px;     /*   t lr b */
    }
    .method-col {
        margin: -10px 0px 60px;
    }
    .method-col:nth-child(3) {
        margin-bottom: 45px;
    }
    .method-col p {
        padding: 0; 
        margin-top: -7px;
    }
}
</style>