<template>
    <section class="header">
        <div class="text-box">
            <h1>{{ this.$store.state.titleHeader }}</h1>
            <p>{{ this.$store.state.subtitleHeader }}</p>
            <OrangeBtn v-if="this.$store.state.showBtnHeader" :text="'Steun ons'" class="btn-header"/>
        </div>
    </section>
</template>

<script>
import OrangeBtn from "@/components/OrangeBtn.vue";
export default {
    name: "Header",
    components: {
        OrangeBtn
    }
}
</script>

<style scoped>
.header {
    min-height: 300px;
    width: 100%;
    background-image: linear-gradient(rgba(4,9,30,0.3), rgba(4,9,30,0.3)), url(../assets/images/banner-home-with-logo4.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}
.text-box {
    width: 80%;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.text-box h1 {
    font-size: 40px;
}
.text-box p {
    margin: 10px 0 40px;
    font-size: 14x;
    color: #FFFFFF;
    max-width: 40vw;
    padding-left: 1px;
}
.btn-header {
    margin-left: 1px;
}
h1 {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
}
p {
    color: #606060;
    font-size: 14px;
    line-height: 22px;
    padding: 5px;
}

@media(max-width: 978px) {
    .text-box h1 {
        font-size: 20px;
    }
    .text-box p {
        margin: 0 0 0;
        max-width: 44vw;
    }
    .btn-header {
        margin-top:11px;
        margin-left: 1.5px;
    }
}

@media(max-width: 600px) {
    .text-box p {
        max-width: 48vw;
    }
}


</style>