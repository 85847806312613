<template>
  <div>
    <router-link @click="scrollToTop" v-if="linkTo=='donation-link'" to="/steun-ons" class="hero-btn">{{ text }}</router-link>
    <router-link @click="scrollToTop" to="/meld-uw-kind-aan" v-if="linkTo=='register-child'" class="hero-btn">{{ text }}</router-link> 
  </div>
</template>

<script>
export default {
    name: "OrangeBtn",
    props: {
        text: {
            type: String,
            default: 'Steun ons'
        }, 
        linkTo: {
            type: String,
            default: 'donation-link'
        } 
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}
</script>

<style>
    .hero-btn {
        display: inline-block;
        text-decoration: none;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        padding: 12px 34px;
        font-size: 16px;
        background: transparent;
        position: relative;
        cursor: pointer;
    }
    .hero-btn:hover {
        border: 1px solid #F44336;
        background: #F44336;
        transition: 1s;
    }
</style>